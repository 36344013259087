export const DATE_FORMATS = {
  fullDateTime: 'YYYY/M/D(ddd)\x20HH:mm:ss',
  fullDate: 'YYYY/M/D(ddd)',
  date: 'M/D(ddd)',
  // v-calendarやdate-picker用のフォーマット
  iso: 'YYYY-MM-DD',
  yearMonth: 'YYYY/M',
  year: 'YYYY',
  month: 'M',
  day: 'D',
  dayOfWeek: 'dd',
  time: 'HH:mm'
} as const;

// TODO: DIしたものを使うようにする
import dayjsBuf from 'dayjs';
import ja from 'dayjs/locale/ja';
dayjsBuf.locale({ ...ja });
export const dayjs = dayjsBuf;
