import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  deleteGuidanceKomaTextbookStudy,
  registerGuidanceKomaTextbookStudy
} from '~/src/infrastructure/apis/graphql/query/mutations';
import { Mutation } from '~/src/domain/entities/schema';
import { GuidanceKomaTextbookStudyRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/guidanceReport/guidanceKomaTextbookStudy';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidanceKomaTextbookStudyRepo: GuidanceKomaTextbookStudyRepositoryInterface =
  {
    register: async input => {
      const { data, errors } = await apolloClient.mutate<Mutation>({
        variables: { input },
        mutation: gql(registerGuidanceKomaTextbookStudy)
      });
      if (errors?.length) return err(errors);
      if (!data) return err(new DataNotExistError());
      logger?.debug(
        'registerGuidanceKomaTextbookStudy response',
        data.registerGuidanceKomaTextbookStudy
      );
      return ok(data.registerGuidanceKomaTextbookStudy);
    },

    delete: async input => {
      const { data, errors } = await apolloClient.mutate<Mutation>({
        variables: { input },
        mutation: gql(deleteGuidanceKomaTextbookStudy)
      });
      if (errors?.length) return err(errors);
      if (!data) return err(new DataNotExistError());
      logger?.debug(
        'deleteGuidanceKomaTextbookStudy response',
        data.deleteGuidanceKomaTextbookStudy
      );
      return ok({ ...data.deleteGuidanceKomaTextbookStudy });
    }
  } as const;
