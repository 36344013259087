import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  // eslint-disable-next-line import/named
  DefaultOptions
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { auth } from '~/src/framework/plugins/di/auth.di';

/**
 * ERROR
 */
export const ERROR_BAD_REQUEST = {
  code: 400,
  type: 'ERROR_BAD_REQUEST',
  message: 'bad request'
};
export const ERROR_NOT_FOUND = {
  code: 404,
  type: 'NOT_FOUND',
  message: 'not found'
};

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
};

const abortController = new AbortController();
/**
 * 認可
 */
// FIXME: 一時的にprocessから環境変数を参照. repositoryをfactoryにして, DIするときに$configから環境変数を受け取りたい
const httpLink = createHttpLink({
  // eslint-disable-next-line no-access-process
  uri: process.env.GRAPHQL_ENDPOINT_MAIN,
  // eslint-disable-next-line no-access-process
  credentials: process.env.HTTP_CREDENTIALS,
  // NOTE: apollo clientがクエリ処理後にabortし、sentry session replayがresponse bodyを取得できなかった問題のため, signalプロパティを渡す: https://github.com/getsentry/sentry-javascript/issues/8345#issuecomment-1595585955
  fetchOptions: {
    mode: 'cors',
    signal: abortController.signal
  }
});
const authLink = setContext(async (_, { headers }) => {
  // amplifyからのトークン取得
  const token = await auth?.getJwtToken();
  return {
    headers: {
      ...headers,
      // トークンをセット
      authorization: `Bearer ${token}`,

      // see: https://gitlab.com/mates-pay/app/new_reco/reco-api/-/issues/2642
      'Mates-App-Name': 'forTeacher'
    }
  };
});

const link = authLink.concat(httpLink);
const cache = new InMemoryCache();

/**
 * export client
 */
export const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions
});
