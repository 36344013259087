// 生徒教材追加（編集）
export const updateStudentTextbook = /* GraphQL */ `
  mutation updateStudentTextbook($input: StudentTextbookUpdateInput!) {
    updateStudentTextbook(input: $input) {
      id
      textbookId
      textbookName
      importantFlag
      progressUnitName
      targetLap
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      subject {
        id
        subjectArea {
          id
          subjectAreaName
          subjects {
            id
            subjectName
          }
        }
      }
    }
  }
`;
