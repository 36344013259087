import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getOptionsForGuidanceKomaTextbookStudy } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const optionsForGuidanceKomaTextbookStudyRepo = {
  fetch: async (studentId: number, currentManagementUserId: number, date: string) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, currentManagementUserId, date },
      query: gql(getOptionsForGuidanceKomaTextbookStudy)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getOptionsForGuidanceKomaTextbookStudy, data',
      data.getOptionsForGuidanceKomaTextbookStudy
    );
    return { ...data.getOptionsForGuidanceKomaTextbookStudy };
  }
} as const;
